import React from "react"
import { Stack, Typography, styled } from "@mui/material"

const heights = [{ height: 80 }, { height: 60 }, { height: 40 }, { height: 20 }, { height: 0 }]

export const ProbabilityIndex: React.FC<{ probability: number }> = ({ probability }) => {
  const color = ((): string => {
    if (probability > 80) return "#F60000"
    if (probability > 60) return "#E7A521"
    if (probability > 40) return "#F6FB02"
    if (probability > 20) return "#C2F327"
    return "#A5E454"
  })()

  return (
    <Stack direction="row" gap="12px" mt="12px">
      <Stack direction="row" gap="8px" sx={{ transform: "rotate(180deg)" }}>
        {heights.map(({ height }) => (
          <SProbBar key={height} filled={probability >= height} color={color} height={height} />
        ))}
      </Stack>

      <SProbBox color={color}>
        <Typography sx={{ color: "#222222", fontWeight: 700, fontSize: "18px" }}>{probability + "%"}</Typography>
      </SProbBox>
    </Stack>
  )
}

const SProbBar = styled("div")<{ height: number; filled: boolean; color: string }>(
  ({ theme, height, filled, color }) => ({
    width: "16px",
    height: `${height + 20}%`,
    background: filled ? color : theme.palette.secondary.A500!,
    transition: "background .5s",
  })
)

const SProbBox = styled(Stack)<{ color: string }>(({ color }) => ({
  width: "62px",
  height: "40px",
  justifyContent: "center",
  alignItems: "center",
  background: color,
  borderRadius: "8px",
  border: "2px solid white",
  transition: "background .5s",
}))
