import React from "react"
import { Stack, Typography, styled } from "@mui/material"

import { formatTime, parseDDtoDMSString } from "@utils"
import { ILocale, IQuake } from "@types"
import { SSubtitle, STypography } from "../_styles"

interface IBiggestQuake {
  quake: IQuake | null
  l: ILocale
}

export const BiggestQuake: React.FC<IBiggestQuake> = ({ quake, l }) => {
  const {
    dashboard: { biggestTitle, noQuakes, coordinates },
  } = l

  if (!quake)
    return (
      <SPlaceholderWrapper>
        <Typography children={noQuakes} sx={{ fontSize: 12, fontWeight: 400 }} />
      </SPlaceholderWrapper>
    )

  const [lat, lng] = parseDDtoDMSString(quake.coord, l.language)

  return (
    <Stack height="86px">
      <SSubtitle children={biggestTitle.toUpperCase()} />

      <STypography children={formatTime(quake.time)} sx={{ marginTop: "6px" }} />
      <STypography children={`${l.mapFilters.magnitude}: ${quake.magnitude}`} />
      <STypography children={`${coordinates} ${lat}, ${lng}`} />
    </Stack>
  )
}

const SPlaceholderWrapper = styled(Stack)({
  height: "86px",
  justifyContent: "center",
  alignItems: "center",
})
