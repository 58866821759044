import React from "react"
import { Box, Stack, Typography } from "@mui/material"

import { IntensityIndex, ProbabilityIndex } from "./fragments"
import { IDashboardParams, ILocale } from "@types"
import { SSubtitle } from "../_styles"

export const StatParams: React.FC<IDashboardParams & { l: ILocale }> = ({ index, probability, l }) => {
  const {
    dashboard: { params, intensityIndex, quakeProb },
  } = l

  return (
    <Stack gap="10px">
      <SSubtitle children={params.toUpperCase()} />
      <Box>
        <Typography children={intensityIndex} sx={{ fontSize: 12 }} />
        <IntensityIndex index={index} l={l} />
      </Box>

      <Box>
        <Typography children={quakeProb} sx={{ fontSize: 12 }} />
        <ProbabilityIndex probability={probability} />
      </Box>
    </Stack>
  )
}
