import React, { useMemo } from "react"
import { PieChart } from "@mui/x-charts/PieChart"
import { Stack, Typography, styled } from "@mui/material"

import { IQuakesToPie } from "../../_types"
import { ILocale } from "@types"
import { useLocalizationContext } from "@context"

const getLegend = (l: ILocale): { color: string; label: string }[] => {
  const { destructive, moderate, weak } = l.dashboard.legend

  return [
    {
      color: "red",
      label: destructive,
    },
    {
      color: "orange",
      label: moderate,
    },
    {
      color: "white",
      label: weak,
    },
  ]
}

export const QuakesPie: React.FC<IQuakesToPie> = ({ total, series }) => {
  const { l } = useLocalizationContext()

  const legend = useMemo(() => getLegend(l), [l])

  return (
    <Stack direction="row" gap="10px" alignItems="center" justifyContent="space-between">
      <SContainer>
        <PieChart
          colors={["red", "orange", "white"]}
          series={[
            {
              data: series,
              innerRadius: 35,
              outerRadius: 50,
              paddingAngle: 0,
              cornerRadius: 0,
              startAngle: 0,
              endAngle: 360,
              cx: 45,
            },
          ]}
          height={100}
          width={100}
        />
        <STitle children={total} />
      </SContainer>

      <Stack width="112px">
        {legend.map((item) => (
          <LegendItem key={item.color} {...item} />
        ))}
      </Stack>
    </Stack>
  )
}

interface ILegendItem {
  color: string
  label: string
}

const LegendItem: React.FC<ILegendItem> = ({ color, label }) => {
  return (
    <Stack direction="row" alignItems="center" gap="5px">
      <SColorBubble color={color} />
      <SLegendLabel children={label} />
    </Stack>
  )
}

const SColorBubble = styled("div")<{ color: string }>(({ color }) => ({
  width: "8px",
  height: "8px",
  borderRadius: "50%",
  background: color,
}))

const SLegendLabel = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  color: theme.palette.secondary.A400,
}))

const SContainer = styled(Stack)({
  position: "relative",
})

const STitle = styled(Stack)({
  width: "80px",
  height: "80px",
  position: "absolute",
  top: "calc(50% - 40px)",
  left: "calc(50% - 40px)",

  justifyContent: "center",
  alignItems: "center",

  fontSize: 30,
  fontWeight: 700,
})
