import React, { useMemo } from "react"
import { Stack, Typography, styled } from "@mui/material"

import { theme } from "@constants"
import { ILocale } from "@types"

const intensity = [
  {
    color: "#A5E454",
    thresdhold: 1,
  },
  {
    color: "#C2F327",
    thresdhold: 2,
  },
  {
    color: "#F6FB02",
    thresdhold: 3,
  },
  {
    color: "#E7A521",
    thresdhold: 4,
  },
  {
    color: "#F60000",
    thresdhold: 5,
  },
]

interface IIntensityIndex {
  index: number
  l: ILocale
}

function getIntensityMessage(index: number, l: ILocale): string {
  const { no, low, mid, hi } = l.dashboard.intensity

  if (index < 1) return no
  if (index < 3) return low
  if (index < 5) return mid
  return hi
}

export const IntensityIndex: React.FC<IIntensityIndex> = ({ index, l }) => {
  const message = useMemo(() => getIntensityMessage(index, l), [index, l])

  return (
    <Stack marginTop="5px">
      <Stack direction="row" alignItems="center" gap="16px">
        <Stack direction="row" alignItems="center" gap="4px">
          {intensity.map(({ color, thresdhold }) => (
            <SIntensityBubble key={color} color={color} filled={index >= thresdhold} />
          ))}
        </Stack>
        <Typography children={index} sx={{ fontSize: 20, fontWeight: 400, lineHeight: "24px" }} />
      </Stack>
      <Typography
        children={message.toUpperCase()}
        sx={{ color: theme.palette.secondary.A400, fontSize: 12, fontWeight: 400, lineHeight: 1 }}
      />
    </Stack>
  )
}

const SIntensityBubble = styled("div")<{ color: string; filled: boolean }>(({ color, filled }) => ({
  width: "14px",
  height: "14px",
  borderRadius: "50%",
  border: "1px solid white",
  background: filled ? color : "",
  transition: "background 1s",
}))
