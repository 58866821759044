import React from "react"

const AccuracyIcon: React.FC = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="8" fill="#353737" />
    <rect x="16" y="6" width="8" height="8" rx="2" fill="#D9D9D9" />
    <rect x="16" y="26" width="8" height="8" rx="2" fill="#D9D9D9" />
    <line x1="6" y1="20" x2="34" y2="20" stroke="#D9D9D9" strokeWidth="2" />
  </svg>
)

const FalseNegIcon: React.FC = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="8" fill="#353737" />
    <path
      d="M21.7321 27C20.9623 28.3333 19.0377 28.3333 18.2679 27L13.0718 18C12.302 16.6667 13.2642 15 14.8038 15L25.1962 15C26.7358 15 27.698 16.6667 26.9282 18L21.7321 27Z"
      fill="#E0E0E0"
    />
  </svg>
)

const FalsePosIcon: React.FC = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="8" fill="#353737" />
    <path
      d="M18.2679 13C19.0377 11.6667 20.9623 11.6667 21.7321 13L26.9282 22C27.698 23.3333 26.7358 25 25.1962 25H14.8038C13.2642 25 12.302 23.3333 13.0718 22L18.2679 13Z"
      fill="#E0E0E0"
    />
  </svg>
)

const PrecisionIcon: React.FC = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="8" fill="#353737" />
    <rect x="17" y="7" width="6" height="6" rx="1" stroke="#D9D9D9" strokeWidth="2" />
    <rect x="17" y="27" width="6" height="6" rx="1" stroke="#D9D9D9" strokeWidth="2" />
    <line x1="6" y1="20" x2="34" y2="20" stroke="#D9D9D9" strokeWidth="2" />
  </svg>
)
const RecallIcon: React.FC = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="8" fill="#353737" />
    <rect x="8" y="9" width="8" height="8" rx="1" stroke="#D9D9D9" strokeWidth="2" />
    <rect x="8" y="23" width="8" height="8" rx="1" stroke="#D9D9D9" strokeWidth="2" />
    <rect x="24" y="9" width="8" height="8" rx="1" stroke="#D9D9D9" strokeWidth="2" />
    <rect x="24" y="23" width="8" height="8" rx="1" stroke="#D9D9D9" strokeWidth="2" />
  </svg>
)

export { AccuracyIcon, FalseNegIcon, FalsePosIcon, PrecisionIcon, RecallIcon }
