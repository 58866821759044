import React from "react"
import { Modal, Stack, Typography, styled } from "@mui/material"

import { useLocalizationContext } from "@context"
import { Cover } from "../../../cover"
import { AccuracyIcon, FalseNegIcon, FalsePosIcon, PrecisionIcon, RecallIcon } from "./icons"
import { IAIData, ILocale } from "@types"

const getDetails = (AIData: IAIData, l: ILocale): IDetailsItem[] => {
  const { falsePositivesTitle, falseNegativesTitle, accuracyTitle, precisionTitle, recallTitle } = l.dashboard.details
  const { false_positives, false_negatives, accuracy, precision, recall } = AIData

  return [
    {
      icon: <FalsePosIcon />,
      title: falsePositivesTitle,
      value: false_positives,
    },
    {
      icon: <FalseNegIcon />,
      title: falseNegativesTitle,
      value: false_negatives,
    },
    {
      icon: <AccuracyIcon />,
      title: accuracyTitle,
      value: `${accuracy * 100}%`,
    },
    {
      icon: <PrecisionIcon />,
      title: precisionTitle,
      value: `${precision * 100}%`,
    },
    {
      icon: <RecallIcon />,
      title: recallTitle,
      value: recall,
    },
  ]
}

interface IDetailsModal {
  AIData: IAIData
  isOpen: boolean
  toggleOpen: () => void
}

export const DetailsModal: React.FC<IDetailsModal> = ({ AIData, isOpen, toggleOpen }) => {
  const { l } = useLocalizationContext()

  const details = getDetails(AIData, l)

  return (
    <SModal open={isOpen} onClose={toggleOpen}>
      <div>
        <Cover
          title={<STitle children={l.dashboard.details.details} />}
          showFullscreenButton={false}
          handleClose={toggleOpen}
        >
          <SContainer>
            {details.map((item) => (
              <DetailsItem key={item.title} {...item} />
            ))}
          </SContainer>
        </Cover>
      </div>
    </SModal>
  )
}

const SContainer = styled(Stack)({
  padding: "16px",
  gap: "16px",
})

const SModal = styled(Modal)({
  top: "calc(50% - 165px)",
  left: "calc(50% - 175px)",
  width: "350px",
  height: "330px",
})

interface IDetailsItem {
  icon: JSX.Element
  title: string
  value: number | string
}

const DetailsItem: React.FC<IDetailsItem> = ({ icon, title, value }) => {
  return (
    <Stack direction="row" alignItems="center" gap="16px">
      {icon}
      <Stack>
        <SText>{title}</SText>
        <STitle>{value}</STitle>
      </Stack>
    </Stack>
  )
}

const STitle = styled(Typography)({
  fontSize: "14px",
  fontWeight: "700",
})

const SText = styled(Typography)({
  fontSize: "12px",
  fontWeight: "400",
})
