import { Typography, styled } from "@mui/material"

const STypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  color: theme.palette.secondary.A400,
  lineHeight: 1.2,
}))

const SSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",

  [theme.breakpoints.down("lg")]: {
    fontSize: "14px",
  },
}))

export { STypography, SSubtitle }
